.content_modal{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.fas{
    align-items: center;
    font-size:600%;
    display: flex;
    margin-right: 10%;
}

.icon svg{
    font-size: 6em;
}

